import * as LP from './LadingPage.styles'
import LOGO from '../../assets/svg/randon_fan.svg'
import Laptop from '../../assets/svg/NewSorteio.svg'
import Phone from '../../assets/svg/iphone.svg'
import Tablet from '../../assets/svg/tablet.svg'
import Button from "../../components/Button/Button";
import { useHistory } from "react-router-dom";
import { NewsToggle } from "./LadingPage.styles";
import Arrow from '../../assets/svg/arrow.svg'

export default function LandingPage() {
    const history = useHistory();

    const handleOnLogin = () => {
        history.push('/login')
    }

    const handleOnRegister = () => {
        history.push('/register')
    }
    return (
        <LP.Wrapper>
            <LP.HeaderWrapper>
                <LP.LogoWrapper>
                    <img src={LOGO} alt="" />
                    <h1>Random Fan</h1>
                </LP.LogoWrapper>
                <LP.ButtonsWrapper>
                    <Button
                        onClick={handleOnLogin}
                        height={'44px'}
                        label={'Entrar'}
                        variant={'lp_text'} hasArrow={false} />
                    <Button
                        onClick={handleOnRegister}
                        height={'44px'}
                        label={'Registrar-se'}
                        variant={'primary'}
                        hasArrow={false} />
                </LP.ButtonsWrapper>
            </LP.HeaderWrapper>
            <LP.PrincipalSectionWrapper>
                <LP.NewsToggle>
                    <span>Temos novidades!</span>
                    <span
                        onClick={handleOnRegister}
                    >Confira agora <img src={Arrow} alt="" /></span>
                </LP.NewsToggle>
                <h1>Gerencie seus sorteios de forma profissional!</h1>
                <h2>Gerencie sorteios profissionalmente no Instagram e economize tempo com nosso gerenciador.
                    Transforme seus sorteios em sucessos e impulsione seu perfil com facilidade.</h2>
                <LP.ButtonsWrapper className={'margin-auto'}>
                    <Button height={'60px'} fontSize={'18px'} label={'Entrar'} variant={'text'} hasArrow={false} />
                    <Button height={'60px'} fontSize={'18px'} label={'Registrar'} variant={'primary'} hasArrow={false} />
                </LP.ButtonsWrapper>
            </LP.PrincipalSectionWrapper>
            <LP.PubSection>
                <LP.PubImg>
                    <img src={Laptop} alt="" onClick={handleOnRegister} />
                </LP.PubImg>
            </LP.PubSection>
            <LP.InfoSection>
                <LP.InfoFunction>
                    <span>Funcionalidades</span>
                    <h3>Muitos recursos úteis!</h3>
                    <span>Aproveite todas as vantagens do Random Fan: realização fácil de sorteios justos e imparciais, verificação automática das regras do sorteio e economia de tempo no processo de seleção do ganhador. </span>
                </LP.InfoFunction>
                <LP.InfoDetailsFunction>
                    <LP.InfoDetails>
                        <LP.RandomDraw>
                            <h3>Sorteio Aleatório</h3>
                            <span>Realize sorteios justos e imparciais no Instagram com facilidade usando a funcionalidade de Sorteio Aleatório</span>
                            <span>Saiba mais <img src={Arrow} alt="" /></span>
                        </LP.RandomDraw>
                        <LP.InfoRules>
                            <h3>Verificação de regras de sorteios</h3>
                            <span>Use nossa ferramenta para verificar facilmente se seus seguidores seguiram as regras de seus sorteios, incluindo comentários,
                                quantidade de comentários, seguindo perfis e permitindo contas comerciais.
                                Economize tempo e tenha certeza de que seus sorteios estão sendo realizados corretamente.</span>
                            <span>Saiba mais <img src={Arrow} alt="" /></span>
                        </LP.InfoRules>
                        <LP.ValidationRules>
                            <h3>Sem validação manual</h3>
                            <span>Não perca mais tempo verificando se o usuário ganhador de sorteios,
                                cumpre todas as regras estabelecidas por você!
                                Todo esse processo é feito de forma automatizada.</span>
                            <span>Saiba mais <img src={Arrow} alt="" /></span>
                        </LP.ValidationRules>
                    </LP.InfoDetails>
                    <LP.InfoImg>
                        <img src={Tablet} alt="" />
                    </LP.InfoImg>
                </LP.InfoDetailsFunction>
            </LP.InfoSection>
            <LP.MetricsSection>
                <h1>Gerencie seus sorteios de forma profissional!</h1>
                <h2>Tudo o que você precisa para converter, engajar e reter mais usuários.</h2>
                <LP.DateMetrics>
                    <LP.InfoMetrics>
                        <h4>400+</h4>
                        <span>Sorteios Realizados</span>
                    </LP.InfoMetrics>
                    <LP.InfoMetrics>
                        <h4>95%</h4>
                        <span>Aprovação entre os usuários</span>
                    </LP.InfoMetrics>
                    <LP.InfoMetrics>
                        <h4>1K+</h4>
                        <span>Usuários ativos</span>
                    </LP.InfoMetrics>
                </LP.DateMetrics>
            </LP.MetricsSection>
            <LP.CtaSection>
                <LP.CtaWrapper>
                    <h3>Inicie com o plano grátis</h3>
                    <span>Junte-se aos mais de 1k de usuários que já organizam seus sorteios pelo Random Fan</span>
                </LP.CtaWrapper>
                <LP.ButtonsWrapper>
                    <Button
                        onClick={handleOnLogin}
                        height={'44px'}
                        label={'Saiba Mais'}
                        variant={'lp_text'} hasArrow={false} />
                    <Button
                        onClick={handleOnRegister}
                        height={'44px'}
                        label={'Registrar-se'}
                        variant={'primary'}
                        hasArrow={false} />
                </LP.ButtonsWrapper>

            </LP.CtaSection>
        </LP.Wrapper>
    )
}
