import { BadgeWrapper } from './Badge.style'
import { AiOutlineLike } from 'react-icons/ai'
import { FaRegComment } from 'react-icons/fa'
interface Props {
    type: 'like' | 'comment',
    count: number
}
export default function Badge(props: Props) {

    const formatNumber = (number: number): string => {
        let result = number.toFixed(2); // limita a duas casas decimais
        if (number >= 1000000) {
            result = (number / 1000000).toFixed(1) + "M"; // divide por 1000000 e adiciona o sufixo "M"
        } else if (number >= 1000) {
            result = (number / 1000).toFixed(1) + "K"; // divide por 1000 e adiciona o sufixo "K"
        }
        return result;
    };
    const badgeIcon = {
        "like": () => { return <AiOutlineLike size={14} /> },
        "comment": () => { return <FaRegComment size={14} /> }
    }

    return <BadgeWrapper>
        <div className="icon">
            {
                badgeIcon[props.type]()
            }
        </div>
        <div className="count">
            <p>
                {formatNumber(props.count)}
            </p>
        </div>
    </BadgeWrapper>
};