import { SortWrapper, NextWrapper, SortButton } from "../Sort.styles";
import React, {useEffect, useState} from 'react';
import Confetti from 'react-confetti';
import Profile from "../../../assets/svg/profile.svg";
import { Link } from "react-router-dom";

export default function SortStepThree() {

    const [showConffeti, setShowConffeti] = useState(false);
    const [data, setData] = useState<any>(null);
    const path = window.location.pathname;
    const [qtdConfeti, setQtdConfeti] = useState(500)

    useEffect(() => {
        if (path !== '/') {
            // @ts-ignore
            const json = JSON.parse(localStorage.getItem('winner'))
            setData(json)
            setShowConffeti(true);
        } else {
            setTimeout(() => {
                setShowConffeti(true);
            }, 1000)
            setTimeout(() => {
                setQtdConfeti(0)
            }, 5000)
        }
    }, [])


    return (
        <SortWrapper>
            {showConffeti && (
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                    numberOfPieces={qtdConfeti}
                    gravity={0.1}
                    colors={['#ff0000', '#fff700', '#00ff00', '#0000ff']}
                />
            )}
            <img src={path === '/' ? Profile : data?.url} alt="" />
            <h2>Congratulations, @{path === '/' ? 'rfinstaof' : data?.winner}</h2>
            <NextWrapper>
                <Link to={'/main'}>
                    <SortButton>Go to main page</SortButton>
                </Link>
            </NextWrapper>
        </SortWrapper>
    )
}
