import { CardWrapper } from './Card.styles'
import Badge from '../Badge/Badge'
import Button from '../Button/Button'
import { useHistory } from 'react-router-dom'
import axiosAuth from "../../interceptors/axios";
import {toast} from "react-toastify";
interface CardProps {
    id: number,
    img: any,
    title: string,
    likes: number,
    comments: number,
    sorted?: boolean,
    winner: string,
}

export default function Card(props: CardProps) {
    const history = useHistory();

    const doSorte = (id: number) => {
        localStorage.setItem('sortid', id.toString())
        history.push('/sort')
    }
    const handleClick = (sorted: boolean, id: number) => {
        if(!sorted) {
            doSorte(id);
        }
    }


    return <CardWrapper>
        <img src={props.img} />
        <p>{props.title}</p>
        <div className="info-section">
            <Badge type="like" count={props.likes} />
            <Badge type="comment" count={props.comments} />
        </div>
        <Button
        onClick={() => handleClick(props.sorted || false, props.id)}
         variant={props.sorted ? 'winner' : 'text'}
         label={props.sorted ? props.winner : 'Sortear'}
         width='144px'
         height='27px'
         fontSize='14px'
         hasArrow={false} />
    </CardWrapper>
};
