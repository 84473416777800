import axios from 'axios';

const baseURL = 'https://api.rfinsta.com/api';
export const axiosBase = axios.create({
  baseURL,
  // outras configurações
});

const axiosAuth = axios.create({
    baseURL,
    // outras configurações
  });


axiosAuth.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Adiciona o cabeçalho de autorização com o token
    }
    return config;
  },
  error => Promise.reject(error)
);

export default axiosAuth;
