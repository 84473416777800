import styled from "styled-components";

export const BadgeWrapper = styled.div`
    
    color: #47AAFF;
    background-color: #47AAFF3B;
    min-width: 46px;
    max-width: 74px;
    max-height: 26px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 2px 4px;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    .icon {
        margin-left: 3px;
    }

    .count { 
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .count > p {
        font-size: 12px;
    }
`