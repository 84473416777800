import styled from "styled-components";

const SIZE = {
  large: {
    width: `900px`,
  },
  medium: {
    width: `800px`,
  },
}


export const Wrapper = styled.div<{
  size: 'large' | 'medium'
}>`
  display: flex;
  justify-content: space-between;
  /* height: 449px; */
  width: ${p => SIZE[p.size].width};
  max-width: 900px;
  background-color: white;
  border-radius: 15px;
`;

export const Left = styled.div`
  /* max-width: 65%; */
  flex-grow: 1;
`;

export const Right = styled.div`
    position: relative;
    min-width: 240px;
    padding: 40px 32px;
    box-sizing: content-box;

    .title {
        text-align: center;
    }

    &::before {
        content: '';
        border-left: 1px solid #dddddd;
        height: calc(100% - 120px);
        display: inline-block;
        position: absolute;
        left: 0;
        top: 60px;
    }
`;
