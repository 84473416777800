import * as T from './TemplateBox.styles'

export interface TemplateBoxProps {
    left: React.ReactNode, 
    right: React.ReactNode, 
    size: 'large' | 'medium'
}

export default function TemplateBox({left, right, size, ...props}: TemplateBoxProps) {
    return <T.Wrapper size={size}>
        { left &&
            <T.Left>
                {left}
            </T.Left>
        }
        { right &&
            <T.Right>
                {right}
            </T.Right>
        }       

    </T.Wrapper>
}