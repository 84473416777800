import {
    FollowInputWrapper,
    ToggleButton,
    NewSortWrapper,
    Box,
    Img,
    InputWrapper,
    DescriptionWrapper,
    Description,
    ToggleWithDescriptionWrapper,
    ToggleWrapper,
    FollowInput
} from "./NewSort.styles"
import Clip from "../../assets/svg/clip.svg"
import {useState} from "react"
import {ReactComponent as CheckIcon} from '../../assets/svg/check.svg';
import {ReactComponent as CloseIcon} from '../../assets/svg/close.svg';
import {ReactComponent as Pencil} from '../../assets/svg/pencil.svg';
import Button from "../../components/Button/Button";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';
import styled from "styled-components"
import axiosAuth from "../../interceptors/axios";
import {toast} from "react-toastify";
import {getUserId} from "../../utils/token";

const StyledLoader = styled(LoadingOverlay)`
`

export default function NewSort() {
    const history = useHistory();
    const [isActive, setIsActive] = useState(false);
    const [seguirOutrosPerfis, setSeguirOutrosPerfis] = useState(false)
    const [comentarNaPublicacao, setComentarNaPublicacao] = useState(false)
    const [permitirContasComerciais, setPermitirContasComerciais] = useState(false)
    const [data, setData] = useState<any>({
        comment_count: null,
        description: null,
        image: null,
        like_count: null

    })

    const handleSeguirOutrosPerfisClick = () => {
        setSeguirOutrosPerfis(!seguirOutrosPerfis);
    };

    const handleComentarNaPublicacaoClick = () => {
        setComentarNaPublicacao(!comentarNaPublicacao);
    };


    const handlePermitirContasComerciais = () => {
        setPermitirContasComerciais(!permitirContasComerciais);
    };


    const handleOnChangeLink = (value: string) => {
        console.log(value)
        setIsActive(true);
        axiosAuth.post('/thumbnail/', {link: value}).then(data => {
            console.log(data.data)
            setData(data.data)
        }).finally(() => {
            setIsActive(false);
        });
    }


    function validatePayload(payload: any): boolean {
        if (!payload.title) {
            toast.error("Título é obrigatório.");
            return false;
        }

        if (!payload.content) {
            toast.error("Conteúdo é obrigatório.");
            return false;
        }

        if (!payload.thumbnail) {
            toast.error("Thumbnail é obrigatório.");
            return false;
        }

        if (!payload.url) {
            toast.error("URL é obrigatória.");
            return false;
        }

        if (!payload.likes) {
            toast.error("Likes é obrigatório.");
            return false;
        }

        if (!payload.user) {
            toast.error("Usuário é obrigatório.");
            return false;
        }

        return true;
    }

    const handleCreate = () => {
        try {
            const payload = {
                "title": data?.description.substring(0, 15),
                "content": data?.description,
                "thumbnail": data?.image,
                "url": data?.url,
                "likes": data?.like_count,
                "comments": data?.comment_count,
                "need_follow": seguirOutrosPerfis,
                "need_comment": comentarNaPublicacao,
                "allow_comercial_account": permitirContasComerciais,
                "user": getUserId()
            }

            if (validatePayload(payload))
                axiosAuth.post('/posts/', payload).then(data => {
                    toast.success('Post criado com sucesso!');
                    history.push('/main');
                }).catch(err => {
                    toast.error('Create post error!')
                    console.error(err);
                })
        } catch (err) {
            toast.error("Please fill in all fields")
        }
    }

    return (
        <StyledLoader
            active={isActive}
            spinner
            text='Loading...'
            styles={{wrapper: {height: '100vh'}}}
        >
            <NewSortWrapper>
                <Img url={data?.image || 'https://api.rfinsta.com/images/imgwait_colored.png'}></Img>
                <Box>
                    <p>Link da Publicação</p>
                    <InputWrapper>
                        <img src={Clip} alt=""/>
                        <input type="text" placeholder="Cole o link aqui"
                               onChange={(e) => handleOnChangeLink(e.target.value)}/>
                    </InputWrapper>

                    <DescriptionWrapper>
                        <p>Descrição:</p>
                        <Description>{data?.description}</Description>
                    </DescriptionWrapper>

                    <ToggleWithDescriptionWrapper>
                        <p>Necessita seguir outros perfis?</p>
                        <ToggleWrapper active={seguirOutrosPerfis} onClick={handleSeguirOutrosPerfisClick}>

                            <ToggleButton active={seguirOutrosPerfis}>
                                {seguirOutrosPerfis ? <CheckIcon/> : <CloseIcon/>}
                            </ToggleButton>
                        </ToggleWrapper>
                    </ToggleWithDescriptionWrapper>
                    <FollowInputWrapper>
                        <FollowInput placeholder="@user"/>
                        <Button hasArrow={false}
                                variant="primary" label=""
                                width="35px"
                                height="32px"
                        >
                            <Pencil/>
                        </Button>
                    </FollowInputWrapper>

                    <FollowInputWrapper>
                        <FollowInput placeholder="@user"/>
                        <Button hasArrow={false}
                                variant="primary" label=""
                                width="35px"
                                height="32px"
                        >
                            <Pencil/>
                        </Button>
                    </FollowInputWrapper>

                    <FollowInputWrapper>
                        <FollowInput placeholder="@user"/>
                        <Button hasArrow={false}
                                variant="primary" label=""
                                width="35px"
                                height="32px"
                        >
                            <Pencil/>
                        </Button>
                    </FollowInputWrapper>

                    <ToggleWithDescriptionWrapper>
                        <p>Necessário Comentar na publicação?</p>
                        <ToggleWrapper active={comentarNaPublicacao} onClick={handleComentarNaPublicacaoClick}>
                            <ToggleButton active={comentarNaPublicacao}>
                                {comentarNaPublicacao ? <CheckIcon/> : <CloseIcon/>}
                            </ToggleButton>
                        </ToggleWrapper>
                    </ToggleWithDescriptionWrapper>

                    <ToggleWithDescriptionWrapper>
                        <p>Permitir Contas Comerciais?</p>
                        <ToggleWrapper active={permitirContasComerciais} onClick={handlePermitirContasComerciais}>

                            <ToggleButton active={permitirContasComerciais}>
                                {permitirContasComerciais ? <CheckIcon/> : <CloseIcon/>}
                            </ToggleButton>
                        </ToggleWrapper>
                    </ToggleWithDescriptionWrapper>
                    <Button onClick={handleCreate} variant="primary" label="Create" hasArrow={false}/>
                </Box>
            </NewSortWrapper>
        </StyledLoader>
    )
}
