import * as F from './Form.styles'
import Input from '../../Input/Input'
import Button from '../../Button/Button'
import { Link, useHistory } from 'react-router-dom';
import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function FormLogin({ ...props }) {
    const history = useHistory();
    const [formValues, setFormValues] = useState({
        username: '',
        password: ''
    });

    const [loading, setLoading] = useState(false);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    const login = () => {
        const { username, password } = formValues;
        if (username === '') {
            toast.error('Username is required');
            return false;
        }
        if (password === '') {
            toast.error('Password is required');
            return false;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append('username', formValues['username']);
        formData.append('password', formValues['password']);

        axios.post('https://api.rfinsta.com/api/login/', formData).then((response) => {
            console.log(response.data);
            localStorage.setItem('token', response.data.tokens.access);
            toast.success('Welcome!');
            history.push('/main');
        }).catch((error) => {
            console.log(error);
            toast.error('Invalid username or password');
        }).finally(() => {
            setLoading(false);
        })
    }


    return <F.Wrapper>
        <F.FormWrapper>
            <h1 className="title">Random Fan</h1>
            <Input onChange={handleInputChange} name='username' label="Login" type="text" />
            <Input onChange={handleInputChange} name='password' label="Password" type="password" />
            <Link to="/recover" className="forgot">Forgot password?</Link>

            <Button onClick={login} loading={loading} hasArrow={true} variant="primary" label="Login" />

        </F.FormWrapper>
        <F.RegisterWrapper>
            <p>Don't have an account?</p>
            <Link to="/register" className="register">Click here to create your account!</Link>
        </F.RegisterWrapper>
    </F.Wrapper>
}
