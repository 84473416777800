import {SortWrapper, NextWrapper, SortButton} from "../Sort.styles";
import Star from "../../../assets/svg/star.svg";
import { Link } from "react-router-dom";
export default function SortStepTwo() {
    return (
        <SortWrapper>
            <img src={Star} alt="" />
            <h2>Let's go!</h2>
                <p>Click in the button below to show winner of this draw</p>
            <NextWrapper>
                <Link to={'/congratulations'}>
                    <SortButton>Show Winner</SortButton>
                </Link>
            </NextWrapper>
        </SortWrapper>
    )
}