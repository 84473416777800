import { ButtonHTMLAttributes, DetailedHTMLProps, InputHTMLAttributes } from 'react'
import * as I from './Input.styles'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string,
    type: 'text' | 'password'
}
export default function Input(props: InputProps) {
    return <I.Wrapper>
        {
            props.label &&
            <span className="Label">
                {props.label}
            </span>
        }
        <input {...props} type={props.type} placeholder={props.placeholder} />
    </I.Wrapper>
}