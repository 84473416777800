import { Link } from "react-router-dom"
import Button from "../Button/Button"
import Card from "../Card/Card"
import { PageWrapper } from "./MainPage.styles"
import { useEffect, useState } from "react"
import axiosAuth from "../../interceptors/axios"
import { randomBytes } from "crypto"

export default function MainPage({ ...props }) {

    const [cards, setCards] = useState<any>([])

    useEffect(() => {
        axiosAuth.get('/posts/').then(data => {
            setCards(data.data)
           
        }).catch(err => { console.log(err) }).finally(() => { })
    }, [])

    return <PageWrapper>
        <div className="header">
            <div className="title">
                <h1>Random Fan</h1>
                <span>Todos os seus sorteios</span>
            </div>
            <div className="button">
                <Link to={'/new'}>
                    <Button label="Novo Sorteio" variant="primary" hasArrow={false} />
                </Link>
            </div>
        </div>
        <div className="body">
            {cards.map((card: any) => (
                <Card
                    key={card.key}
                    id={card.id}
                    title={card.title}
                    likes={card.likes}
                    sorted={card.sorted}
                    winner={card.winner}
                    comments={card.comments}
                    img={card.thumbnail}
                />
            ))}
        </div>
    </PageWrapper>
}
