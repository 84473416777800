import styled from "styled-components"

export const NewSortWrapper = styled.div`
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`
interface ImgProps {
    url: string;
}

export const Img = styled.img<ImgProps>`
    height: 455px;
    width: 455px;
    background-image: url('${(props) => props.url}');
    background-position: center;
    background-size: cover;
    border-radius: 16px;
    margin-right: -24px;
    z-index: 9;
`

export const Box = styled.div`
    width: 598px;
    min-height: 605px;
    background-color: white;
    border-radius: 17px;
    padding: 48px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height */
        user-select: none;
        text-transform: capitalize;

        color: #6B6E8D;
    }
`

export const InputWrapper = styled.div`
    background-color: #CED0E4;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    gap: 8px;
    input {
        color: #6B6E8D;
        background: none;
        border: none;
        width: 100%;
        outline: none;
    }
`

export const DescriptionWrapper = styled.div`

`

export const Description = styled.p`
    white-space: pre-line;
    height: 158px;
    overflow: auto;
`

export const ToggleWithDescriptionWrapper = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`

interface FollowProps {
    active: boolean;
}
export const ToggleWrapper = styled.div<FollowProps>`
    width: 38px;
    height: 23px;
    background: #eeeff8;
    border-radius: 6px;
    display: flex;
    align-items: center;
    transition: all 2s ease-in-out;
    justify-content: ${(props) => (props.active ? 'flex-start' : 'flex-end')};
    padding: 2px;
    box-sizing: border-box;
    cursor: pointer;
`;

export const ToggleButton = styled.div<FollowProps>`
    width: 19px;
    height: 19px;
    background: ${(props) => (props.active ? '#C3EFDF' : '#f5c5d0')};
    border-radius: 4px;
    transition: all .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
`;


export const FollowInputWrapper = styled.div`
    display: flex;
    gap: 16px;

`

export const FollowInput = styled.input`
    outline: none;
    width: 132px;
    height: 32px;
    border-radius: 4px;
    background-color: #EEEFF8;
    border: none;
    padding: 10px 8px;
`