import styled from "styled-components"

export const CardWrapper = styled.div`
    width: 160px;
    height: 222px;
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 8px;
    transition: .2s;
    overflow: hidden;

    p{
        padding: 4px;
        font-size: smaller;
    }

    .info-section {
        display: flex;
        align-content: center;
        width: 100%;
        justify-content: space-around;
    }

    img {
        height: 160px;
        max-width: 160px;
        width: 100%;
        border-radius: 8px;
        margin-bottom: 5px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, .2);
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
        height: 260px;
    }

    button {
        margin-top: 10px
    }
`