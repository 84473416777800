import styled from "styled-components"

export const SortWrapper = styled.div`
    margin: 100px auto;
    width: 566px;
    height: 539px;
    background-color: white;
    border-radius: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 48px;

    img {
        margin-top: -80px;
    }

    h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 54px;
        text-align: center;
        /* identical to box height */

        text-transform: capitalize;

        color: #000000;
    }

    h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        text-transform: lowercase;
        color: #000000;

    }
`

export const ProgressBarWrapper = styled.div`
    width: 100%;
    height: 40px;
    background-color: #eee;
    border-radius: 6px;
    overflow: hidden;
`

interface ProgressBarProps {
    progress: number;
  }

export const ProgressBar = styled.div<ProgressBarProps>`
    height: 100%;
    background: #47AAFF;
    transition: 2s;
    width: ${(props) => props.progress + "%"};
`

export const NextWrapper = styled.div` 
    display: flex;
    flex-direction: column;
    text-align: center;
`

export const SortButton = styled.button`
    width: 429px;
    height: 44px;
    cursor: pointer;
    background: #47AAFF;
    border-radius: 4px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-transform: capitalize;
    color: #FFFFFF;
    border: none;
`