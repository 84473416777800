import styled from 'styled-components'
import { transparentize } from 'polished'


export const Wrapper = styled.label`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 64px;

    span.Label {
        font-size: 15px;
        font-weight: 500;
        color: #6B6E8D;
    }

    input {
        width: 100%;
        height: 40px;
        background-color: #EEEFF8;
        border: none;
        padding: 10px;
        font-size: 15px;
        border-radius: 5px;

        ::placeholder {
            color: #CED0E4;
        }

        
    }
`