import Announcement from "../../components/Login/Announcement/Annoucement";
import FormLogin from "../../components/Login/Form/Form";
import TemplateBox from "../../components/TemplateBox/TemplateBox";
import {useEffect} from "react";

export default function LoginPage() {
    useEffect(() => {
        localStorage.clear();
    }, [])
    return (
        <div className="page-wrapper">
            <TemplateBox left={<Announcement/>} right={<FormLogin/>} size="medium"/>
        </div>
    )
}
