import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react'
import * as B from './Button.styles'
import { Oval } from 'react-loader-spinner'

export interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    label: string,
    variant: 'primary' | 'danger' | 'text' | 'winner' | 'lp_text',
    hasArrow: boolean,
    width?: string,
    height?: string,
    loading?: boolean,
    loadingWidth?: number,
    loadingHeight?: number,
    loadingColor?: string,
    fontSize?: string,
}

export default function Button(props: ButtonProps) {
    return <B.Wrapper
        variant={props.variant}
        width={props.width}
        height={props.height}
        onClick={props.onClick}
        disabled={props.loading}
        fontSize={props.fontSize}
        className={props.hasArrow && !props.loading ? 'hasArrow' : ''}
    >
        <>
            {props.label}
            {props.children}
            {props.loading && <Oval
                height={props.loadingHeight || 12}
                width={props.loadingWidth || 20}
                color={props.loadingColor || "#fff"}
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="transparent"
                strokeWidth={2}
                strokeWidthSecondary={8}

            />
            }

        </>

    </B.Wrapper>
}
