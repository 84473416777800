import { ChangeEvent, useEffect, useRef, useState } from "react";
import Button from "../../Button/Button"
import Input from "../../Input/Input"
import * as F from "./RegisterForm.styles"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { useHistory } from "react-router-dom";

export default function RegisterForm({ ...props }) {
    const history = useHistory();
    const [formValues, setFormValues] = useState({
        username: '',
        insta_user: '',
        email: '',
        confirm_email: '',
        password: '',
        confirm_password: ''
    });

    const debounceTimer = useRef<NodeJS.Timeout | null>(null);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }
        
        debounceTimer.current = setTimeout(() => {
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value
            }));
        }, 500);
    };


    useEffect(() => {
       console.log(formValues)
    }, [formValues]);

    useEffect(() => {
        if (formValues['insta_user'] === '')
            return;
        axios.get(`https://api.rfinsta.com/api/check/${formValues['insta_user']}`).catch((error) => {
            if(error.request.status == 400)
                toast.error('Instagram user already exists');
        })
    }, [formValues['insta_user']]);

    const verifyInputs = () => {
        const { username, insta_user, email, confirm_email, password, confirm_password } = formValues;
        console.log('verificando')
        if (username === '') {
            toast.error('Username is required');
            return false;
        }
        if (insta_user === '') {
            toast.error('Instagram user is required');
            return false;
        }
        if (email === '') {
            toast.error('Email is required');
            return false;
        }
        if (confirm_email === '') {
            toast.error('Confirm email is required');
            return false;
        }
        if (password === '') {
            toast.error('Password is required');
            return false;
        }
        if (confirm_password === '') {
            toast.error('Confirm password is required');
            return false;
        }
        if (email !== confirm_email) {
            toast.error('Emails must be the same');
            return false;
        }
        if (password !== confirm_password) {
            toast.error('Passwords must be the same');
            return false;
        }
        return true;
    }

    const handleRegister = () => {
        if (verifyInputs()) {
            register();
        }
    }

    const register = () => {
        const { username, insta_user, email, password } = formValues;
        axios.post('https://api.rfinsta.com/api/register/', {
            username,
            insta_user,
            email,
            password
        }).then((response) => {
            console.log(response)
            toast.success('Registered successfully');
            history.push('/');
        }).catch((error) => {
            toast.error(`Error registering: ${Object.values(error.response.data).join(", ")}`);
            console.log(error)
        })
    }

    return <>
        <F.FormWrapper>
            <h1 className="title">Register your account</h1>
            <label className="subtitle">Fill in the data correctly to complete your registration</label>
            <div className="form-section">
                <div className="left-section">
                    <Input onChange={handleInputChange} name="username" label="Username" type="text" />
                    <Input onChange={handleInputChange} name="email" label="Email" type="text" />
                    <Input onChange={handleInputChange} name="password" label="Password" type="password" />
                </div>
                <div className="right-section">
                    <Input onChange={handleInputChange} name="insta_user" label="Instagram User" type="text" />
                    <Input onChange={handleInputChange} name="confirm_email" label="Confirm Email" type="text" />
                    <Input onChange={handleInputChange} name="confirm_password" label="Confirm Password" type="password" />
                </div>
            </div>
            <div className="register-button">
                <Button onClick={() => handleRegister()} hasArrow={true} variant="primary" label="Register" />
            </div>

        </F.FormWrapper>
    </>
};
