import styled from 'styled-components'


export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`


export const FormWrapper = styled.div`
    .title {
        margin-bottom: 32px;
    }
    label ~ label {
        margin-top: 16px;
    }
    .forgot {
        font-size: 12px;
        color: #6B6E8D;
        display: block;
        margin-top: 8px;
        margin-bottom: 16px;

        text-decoration: none;
    }
`

export const RegisterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    font-size: 12px;

`