import styled from 'styled-components'

export const PageWrapper = styled.div`
    height: 600px;
    width: 700px;
    
    .header {
        display:flex;
        justify-content: space-between;
        align-items: center;

        .title {
            height: 60px;
            width: 200px;
        }
        .button{
            height: 30px;
            width: 250px;
        }
    }

    .body {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        grid-gap: 20px;
    }
`