import Announcement from "../../components/Register/Announcement/Announcement";
import FormRegister from "../../components/Register/Form/RegisterForm";
import TemplateBox from "../../components/TemplateBox/TemplateBox";

export default function RegisterPage() {
    return (
        <div className="page-wrapper">
            <TemplateBox left={<Announcement/>} right={<FormRegister/>} size="large"/>
        </div>
    )
}