import styled from 'styled-components'
import {transparentize} from 'polished'

const COLORS = {
    primary: '#47AAFF',
    danger: '#FF4747',
    text: 'transparent',
    winner: '#37CB95'
}

const THEMES = {
    primary: {
        border: COLORS.primary,
        background_color: COLORS.primary,
        color: 'white',
        onHover: `
        box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
        `,
        disabled: {
            bg: transparentize(0.44, COLORS.primary),
            color: '#fff'
        }
    },
    danger: {
        border: COLORS.danger,
        background_color: COLORS.danger,
        color: 'white',
        onHover: `
        box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
        `,
        disabled: {
            bg: transparentize(0.44, COLORS.danger),
            color: '#fff'
        }
    },
    text: {
        border: COLORS.primary,
        background_color: COLORS.text,
        color: COLORS.primary,
        onHover: `
        box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
        `,
        disabled: {
            bg: transparentize(0.44, COLORS.danger),
            color: '#fff'
        }
    },
    lp_text: {
        border: COLORS.text,
        background_color: COLORS.text,
        color: COLORS.primary,
        onHover: `
        box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
        `,
        disabled: {
            bg: transparentize(0.44, COLORS.danger),
            color: '#fff'
        }
    },
    winner: {
        border: '#E1F7EF',
        background_color: '#E1F7EF',
        color: COLORS.winner,
        onHover: `
        box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
        `,
        disabled: {
            bg: transparentize(0.44, COLORS.danger),
            color: '#fff'
        }
    }
}

export const Wrapper = styled.button<{
    variant: 'primary' | 'danger' | 'text' | 'winner' | 'lp_text',
    width?: string,
    height?: string,
    fontSize?: string
}>`
  font-family: 'Inter', 'Montserrat Alternates', sans-serif;
  cursor: pointer;
  padding: 10px;
  width: ${p => p.width || '100%'};
  height: ${p => p.height || '100%'};
  border-radius: 5px;
  background-color: ${p => THEMES[p.variant].background_color};
  border: 1px solid ${p => THEMES[p.variant].border};
  color: ${p => THEMES[p.variant].color};
  font-size: ${p => p.fontSize || '16px'};
  transition: .2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    ${p => THEMES[p.variant].onHover};
  }

  &:disabled {
    background-color: ${p => THEMES[p.variant].disabled.bg};
    color: ${p => THEMES[p.variant].disabled.color};
    pointer-events: none;
    border-color: transparent;
  }

  &.hasArrow {
    &::after {
      content: '';
      display: inline-block;
      border-right: 2px solid white;
      border-top: 2px solid white;
      transform: rotate(45deg);
      width: 6px;
      height: 6px;
      margin-left: 7px;
    }
  }
`
