import styled from 'styled-components'

export const FormWrapper = styled.div`
    .title{

    }
    
    label ~ label {
        margin-top: 16px;
    }

    .subtitle{
        font-size: 14px;
        color: #6B6E8D
    }
    .form-section{
        width: 80%;
        margin: 20px auto 15px 10px;
        
    }
    .form-button{
        margin-top: 20px
    }
`