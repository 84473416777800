import styled from 'styled-components'

export const Wrapper = styled.div`
    padding: 40px 32px 0;
    h2 {
        font-size: 24px;
        margin-bottom: 32px;
        .highlight {
            text-transform: uppercase;
            color: #48AAFF;
        }
    }

    img {
        display: block;
    }
`