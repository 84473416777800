import styled from "styled-components";

export const FormWrapper = styled.div`
    .title {
        text-align:start;
    }
    label ~ label {
        margin-top: 16px;
    }
    .subtitle{
        font-size:15px;
        color: #CED0E4;
    }
    .form-section {
        display: flex;
        margin-top: 15px;
    }
    .left-section {

    }
    .right-section {
        margin-left: 20px;
    }
    .register-button{
        width: 200px;
        margin: 15px auto 0;
    }
`