import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import {Exception} from "sass";
import {toast} from "react-toastify";

interface PrivateRouteProps extends RouteProps {
    component: React.ComponentType;
}

interface TokenPayload {
    sub: string;
    exp: number;
    iat: number;
    // outras informações que podem estar contidas no token
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {

    function isTokenValid(): boolean {
        try {
            const token = localStorage.getItem('token')
            if (!token)
                throw new Error("Session expired!");


            const decodedToken = jwtDecode<TokenPayload>(token);
            const expirationDate = new Date(decodedToken.exp * 1000);

            // verifica se o token expirou
            if (expirationDate <= new Date()) {
                return false;
            }

            // verifica se o token foi revogado pelo servidor
            // implementar lógica de verificação do servidor aqui

            return true;
        } catch (err) {
            // erro ao decodificar o token
            toast.error('Session expired')
            return false;
        }
    }

    return (
        <Route
            {...rest}
            render={({ location }) => {
                return isTokenValid() ? (
                    <Component />
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: location } }} />
                );
            }}
        />
    );
};

export default PrivateRoute;
