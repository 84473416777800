import Announcement from "../../components/Recover/Announcement/Announcement";
import FormRecover from "../../components/Recover/Form/Form";
import TemplateBox from "../../components/TemplateBox/TemplateBox";

export default function RecoverPage() {
    return (
        <div className="page-wrapper">
            <TemplateBox left={<Announcement/>} right={<FormRecover/>} size="medium"/>
        </div>
    )
}