import styled from "styled-components";

export const Wrapper = styled.div`
  width: 1440px;
  background-color: #ECF9FF;
  margin: auto;
  padding-left: 112px;
  padding-right: 112px;
  font-family: 'Inter', "Montserrat Alternates", sans-serif;
`

export const HeaderWrapper = styled.header`
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat Alternates", sans-serif;
`

export const LogoWrapper = styled.div`
  display: flex;
  gap: 17px;
  align-items: center;

  img {
    height: 44px;
  }

  h1 {
    font-size: 24px;
    color: black;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 18px;
  width: 300px;
`

export const PrincipalSectionWrapper = styled.section`

  h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 72px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #004162;
  }

  h2 {
    margin: 24px auto 48px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    width: 768px;
    text-align: center;
    color: #005F95;
  }
`

export const NewsToggle = styled.div`
  margin: 96px auto 16px;
  background-color: #DAF0FE;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 4px 4px 14px;
  gap: 12px;
  mix-blend-mode: multiply;
  border-radius: 16px;
  width: 276px;
  height: 32px;
  justify-content: space-between;
  font-size: 14px;

  span:last-child {
    cursor: pointer;
    border-radius: 16px;
    padding: 4px 8px;
    display: flex;
    background-color: #ECF9FF;
    gap: 4px;
  }
`

export const PubSection = styled.div`
  display: flex;
  margin: 64px auto 0;
  align-items: center;
  justify-content: center;
`
export const PubImg = styled.div`
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
export const InfoSection = styled.div`
  margin: 96px auto 0;


`
export const InfoFunction = styled.div`
      margin: 64px auto;

      span:first-child {
        display: flex;
        font-weight: bold;
        color: #005F95;
        margin-bottom:12px;
      }

      h3{
      display:flex;
      font-weight: 600;
      font-size: 36px;
      margin-bottom:12px;
      }

      span:last-child {
        display: flex;
        font-weight: 400;
        font-size: 20px;
        max-width:768px;
      }
`
export const InfoDetailsFunction = styled.div`
      display: flex;
      margin-left: 24px;
      justify-content: space-between;
      gap: 20px;

`
export const InfoDetails = styled.div`
      margin-bottom: 24px;
`


export const RandomDraw = styled.div`
   margin-bottom: 24px;   
      h3{
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 10px;
    }
      span{
      display: flex;
      margin-bottom: 20px;
      gap: 2px;
    }

    span:last-child{
      color: #005F95;

    }
`

export const InfoRules = styled.div`
   margin-bottom: 24px;   
      h3{
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 10px;
    }
      span{
      display: flex;
      margin-bottom: 20px;
      gap: 2px;
    }

    span:last-child{
      color: #005F95;

    }
`
export const ValidationRules = styled.div`
   margin-bottom: 24px;   
      h3{
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 10px;
    }
      span{
      display: flex;
      margin-bottom: 20px;
      gap: 2px;
    }

    span:last-child{
      color: #005F95;

    }
`

export const InfoImg = styled.div`
    margin-right: -110px;
`
export const MetricsSection = styled.div`
    margin: 96px auto 160px;


    h1{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 72px;
    text-align: center;
    letter-spacing: -0.02em;
    }

    h2{
      margin: 24px auto 128px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      width: 768px;
      text-align: center;
    }
`
export const DateMetrics = styled.div`
  display: flex;
  justify-content: space-around;

`

export const InfoMetrics = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

h4{
  color: #009CE5;
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
}
span{
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

`

export const CtaSection = styled.div`
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 126px;
`

export const CtaWrapper = styled.div`
  h3{
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 36px;
  }

  span{
    font-weight: 400;
    font-size: 20px;
  }
`