import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import LoginPage from './pages/Login/LoginPage';
import RegisterPage from './pages/Register/RegisterPage';
import RecoverPage from './pages/Recover/RecoverPage';
import MainPage from './pages/Main/MainPage';
import SortStepOne from './pages/Sort/StepOne/StepOne';
import SortStepTwo from './pages/Sort/StepTwo/StepTwo';
import SortStepThree from './pages/Sort/StepThree/StepThree';
import NewSort from './pages/NewSort/NewSort';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import LandingPage from "./pages/LandingPage/LadingPage";

function App() {
  return (<>

    <ToastContainer />
    <Router>
      <Switch>
        <PrivateRoute path="/new" component={NewSort} />
        <PrivateRoute path="/congratulations" component={SortStepThree}/>
        <PrivateRoute path="/already" component={SortStepTwo}/>
        <PrivateRoute path="/sort" component={SortStepOne}/>
        <Route path="/recover">
          <RecoverPage />
        </Route>
        <Route path="/register">
          <RegisterPage />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <PrivateRoute path="/main" component={MainPage}/>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/">
          <LandingPage />
        </Route>
      </Switch>
    </Router>
  </>
  );
}

export default App;
