import { SortWrapper, ProgressBarWrapper, ProgressBar } from "../Sort.styles";
import House from "../../../assets/svg/house.svg";
import { useEffect, useState } from "react";
import axiosAuth from "../../../interceptors/axios";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

export default function SortStepOne() {

    const [progress, setProgress] = useState(0);

    const history = useHistory();

    const runSort = () => {
        axiosAuth.post(`/sort/${localStorage.getItem('sortid')}`).then((data) => {
            history.push('/already');
            console.log(data.data.winner)
            localStorage.setItem('winner',  JSON.stringify(data.data))
        }).catch((err: any) => {
            console.log(err)
            toast.error(err.response.data.message)
        })
    }

    useEffect(() => {
        if (progress === 0) {
            runSort();
            for (let i = 0; i < 100; i++) {
                const sleepTime = Math.floor(Math.random() * 10000); // gera um número aleatório entre 0 e 5000
                setTimeout(() => {
                    setProgress(i)
                }, sleepTime);
            }
        }

    }, []);
    return (
        <SortWrapper>
            <img src={House} alt="" />
            <h2>Drawing...</h2>
            <h4>wait a minute, we're randomly drawing the result</h4>
            <ProgressBarWrapper>
                <ProgressBar progress={progress} />
            </ProgressBarWrapper>
        </SortWrapper>
    )
}
