import Button from '../../Button/Button'
import Input from '../../Input/Input'
import * as F from './Form.styles'

export default function RecoverForm({ ...props }) {
    return <F.FormWrapper>
        <h1 className="title">Recover your password</h1>
        <label className="subtitle">Fill in the data correctly to recover your password</label>
        <div className="form-section">
            <Input label="Email" type="text" />
            <Input label="Username" type="text" />
            <div className="form-button">
                <Button label="Send Email" variant="primary" hasArrow={true} />
            </div>

        </div>
    </F.FormWrapper>
}